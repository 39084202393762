//action creators for Banner
import { IAction } from 'redux-micro-frontend';
import { Notification, NotificationDTO } from "./notifications.model"
import { NotificationActionTypes } from './notifications.action.type';

export const PushNotification = (payload: Notification): IAction<Notification> => {
    return {
        type: NotificationActionTypes.Push_Notification,
        payload: payload
    }
}
export const MarkNotification = (payload: Notification): IAction<Notification> => {
    return {
        type: NotificationActionTypes.Mark_Notification,
        payload: payload
    }
}
export const DismissNotification = (payload: Notification): IAction<Notification> => {
    return {
        type: NotificationActionTypes.Dismiss_Notification,
        payload: payload
    }
}
export const ResetNotificationCollection = (payload: NotificationDTO): IAction<NotificationDTO> => {
    return {
        type: NotificationActionTypes.Reset_Notification_Collection,
        payload: payload
    }
}