import { IRootScope } from "../interfaces/IRootScope";
import { ILogger } from "../interfaces/ILogger";
import { FxpContext } from "../context/FxpContext";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpConstants, ApplicationConstants, PerfMarkers, CustomEvents } from "../common/ApplicationConstants";
import { FxpConfigurationService } from "./FxpConfiguration";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { UserInfoService } from "./UserInfoService";
import { CommonUtils } from "../utils/CommonUtils";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import { FxpLogHelper } from "../telemetry/FxpLogHelper";
import { UserInfo } from "../common/UserInfo";
import { IHttpPromise } from "angular";
import { FxpBroadcastedEvents } from "./FxpBroadcastedEvents";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { PlatformStoreFactory } from "../../app/store/platform.store.factory";
import { AddLoggedInUserProfile, AddCurrentUserProfile } from "../../app/profile/profile.action";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { SystemEvent } from "../telemetry/SystemEvent";
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { IUserInfo } from "../interfaces/IUserInfo";
import { MsalAuthenticationService } from "./MsalAuthenticationService";
//import { FxpLoggerService } from "../telemetry/FxpLogger";


/**
 * A service to connect to user profile service to fetch the detaisl of user info and user claims related to partner apps
 * @class Fxp.Services.UserProfileService
 * @classdesc A service to connect to user profile service to fetch the user profile details and claims
 * @example <caption> Example to create an instance of user profile service</caption>
 *  //Initializing User Profile Service
 *  angular.module('FxPApp').controller('AppController', ['UserProfileService', AppController]);
 *  function AppController(userProfileService){ userProfileService.getUserThumbnail(upn); }
 */
export class UserProfileService implements IFxPService {
  private q: angular.IQService;
  private rootScope: IRootScope;
  private timeout: angular.ITimeoutService;
  private sleepInterval: number;
  private msalAuthenticationService: MsalAuthenticationService;
  private profileApiEndpoint: string;
  private claimsApiEndpoint: string;
  private loggedInUserAlias: string;
  private fxplogger: ILogger;
  private fxpcontext: FxpContext;
  private userAlias: string;
  private fxpMessageSvc: FxpMessageService;
  private fxpConfiguration: FxpConfigurationService;
  private iCount: number = 0;
  private iReqCount: number = 0;
  private iUCount: number = 0;
  private telemetryContext: TelemetryContext;
  private userInfoService: UserInfoService;
  public currentUserAlias;
  private telemetrySource = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserProfileService`;
  private responseStartTime = 0;
  private isBasicProfileRefreshed = true;
  private graphAPIProfileUrl: string;
  private graphAPIUserThumbnailUrl: string;
  private graphAPIUserProfileUrl: string;
  private defaultRoleGroupId: number;
  private defaultBusinessRoleId: number;
  private searchProfileApiEndpoint: string;
  private isProfileRequired: boolean;
  constructor(private $http: angular.IHttpService, $q: angular.IQService, $rootScope: IRootScope, $timeout: angular.ITimeoutService, loggerService: ILogger, msalAuthenticationService: MsalAuthenticationService, fxpMessage: FxpMessageService, fxpContext: FxpContext, fxpConfiguration: FxpConfigurationService,
    fxpTelemetryContext: TelemetryContext, userInfoService: UserInfoService, private globalStoreService: FxpGlobalStoreService) {
    this.telemetryContext = fxpTelemetryContext;
    this.q = $q;
    this.rootScope = $rootScope;
    this.timeout = $timeout;
    this.sleepInterval = 100;
    this.msalAuthenticationService = msalAuthenticationService;
    this.fxplogger = loggerService;
    this.fxpMessageSvc = fxpMessage;
    this.fxpConfiguration = fxpConfiguration;
    this.fxpcontext = fxpContext;
    this.userAlias = "";
    this.loggedInUserAlias = "";
    this.currentUserAlias = "";
    this.userInfoService = userInfoService;
    this.graphAPIProfileUrl = fxpConfiguration.FxpAppSettings.GraphAdEndPoint;
    this.graphAPIUserProfileUrl = fxpConfiguration.FxpAppSettings.GraphApiUserProfileUrl;
    this.graphAPIUserThumbnailUrl = fxpConfiguration.FxpAppSettings.GraphApiUserThumbnailUrl;
    let profileStore = window["tenantConfiguration"].ProfileStore || {};
    this.profileApiEndpoint = profileStore.ProfileAPIEndPoint;
    this.searchProfileApiEndpoint = profileStore.SearchAPIEndPoint;
    this.isProfileRequired = window["tenantConfiguration"].IsProfileRequired;
  }

  /**
   * Gets thumbnail image from the graph api service
   * @method Fxp.Services.UserProfileService.getUserThumbnail
   * @param {Email} upn a User Principal Name (UPN) is the name of a system user in an e-mail address format.
   * @param {Boolean} deferred a boolean value which is false by default.
   * @example <caption> Example to invoke getUserThumbnail</caption>
   *  userProfileService.getUserThumbnail('logged in user email');
   */
  getUserThumbnail(upn: string, parentDeferred: any): any {
    let url = this.graphAPIUserProfileUrl + upn + this.graphAPIUserThumbnailUrl;
    let deferred = parentDeferred || this.q.defer();
    let self = this;

    if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
      self.iCount++;
      if (self.iCount == 5) {
        // log only error. 
        self.fxplogger.logError(self.telemetrySource, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.GetUserThumbnail_TokenNotFound, null, null, null, null, ErrorSeverityLevel.Medium);
        return deferred.promise;
      }
      self.timeout(function () {
        self.getUserThumbnail(upn, deferred);
      }, self.sleepInterval);
    }
    else {
      self.iCount = 0;
      let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.Authorization.FetchUserThumbnail';
      let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Fetching user thumbnail from graph');
      let customProperties = self.fxplogger.createPropertyBag();
      customProperties.addToBag(TelemetryConstants.CLASSNAME, "UserProfileService");
      customProperties.addToBag(TelemetryConstants.METHODNAME, "getUserThumbnail");
      self.fxplogger.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".UserProfileService", eventData, customProperties);

      return self.$http({
        url: url,
        method: "get",
        responseType: "blob"
      });
    }

    return deferred.promise;
  };

  /**
   * Gets user's basic profile details by connecting to the service through the url
   * @method Fxp.Services.UserProfileService.getBasicUserProfile
   * @example <caption> Example to invoke getBasicUserProfile</caption>
   *  userProfileService.getBasicUserProfile();
   */
  getBasicUserProfile(pDeferred: Object, showBanner?: boolean): angular.IPromise<string> {
    if (!this.isBasicProfileRefreshed)
      console.warn("getBasicUserProfile was called before basic profile details were refreshed");
    return this.getBasicProfileByOID(this.userInfoService.getCurrentUserOID(), pDeferred, showBanner);
  }
  /**
   * Gets user's latest basic profile details by connecting to the service through the url
   * @method Fxp.Services.UserProfileService.refreshBasicUserProfileInContext
   * @example <caption> Example to invoke refreshBasicUserProfileInContext</caption>
   *  userProfileService.refreshBasicUserProfileInContext();
   */
  refreshBasicUserProfileInContext(pDeferred: any): angular.IPromise<string> {
    let self = this;
    let deferred = pDeferred || this.q.defer();
    self.isBasicProfileRefreshed = false;
    self.responseStartTime = performance.now();
    console.log("Basic Profile Api Call Start Time : " + self.responseStartTime);
    self.getBasicProfile(this.currentUserAlias, true, deferred);
    return deferred.promise;
  }
  /**
   * Gets user's basic profile details for passed in Alias
   * @method Fxp.Services.UserProfileService.getBasicProfileByOID
   * @param {Alias} alias an alias of a user
   * @param {Object} deferred holds promise of a parent caller if set
   * @example <caption> Example to invoke getBasicProfileSvc</caption>
   *  userProfileService.getBasicProfileByOID('<<user AAD Object ID>>');
   */
  public getBasicProfileByOID(aadObjectID: string, pDeferred?: any, showBanner?: boolean): angular.IPromise<string> {
    let self = this;
    let deferred = pDeferred || this.q.defer();
    let userData = null;
    // Removed as part of FXP Event Cleanup (keep comments for near future reference)
    if (self.userInfoService.getLoggedInUserOID() == aadObjectID) {
      userData = self.globalStoreService.GetPlatformState().LoggedInUser.Profile;

    }
    else if (aadObjectID == self.userInfoService.getCurrentUserOID()) {
      let currentProfile = self.globalStoreService.GetPlatformState().CurrentUser.Profile;
      if (!CommonUtils.isNullOrEmpty(currentProfile) && aadObjectID == currentProfile.AadObjectId)
        userData = currentProfile;

    }
    if (!CommonUtils.isNullOrEmpty(userData)) {
      self.timeout(() => {
        deferred.resolve(userData);
      }, self.sleepInterval);

    }
    else {
      self.getBasicProfile(aadObjectID, true, deferred, showBanner);
    }
    return deferred.promise;
  }
  public getBasicProfileByAlias(alias: string, pDeferred?: any, showBanner?: boolean,includeInactiveUser: boolean = false): angular.IPromise<string> {
    if (includeInactiveUser)
      return this.getBasicProfileByAliasIncludingInActiveUser(alias);
    let self = this;
    let deferred = pDeferred || this.q.defer();
    let userData = null;
    // Removed as part of FXP Event Cleanup (keep comments for near future reference)
    if (self.loggedInUserAlias == alias) {
      userData = self.globalStoreService.GetPlatformState().LoggedInUser.Profile;

    }
    else if (alias == self.currentUserAlias) {
      let currentProfile = self.globalStoreService.GetPlatformState().CurrentUser.Profile;
      if (!CommonUtils.isNullOrEmpty(currentProfile) && alias == currentProfile.alias)
        userData = currentProfile;

    }
    if (!CommonUtils.isNullOrEmpty(userData)) {
      self.timeout(() => {
        deferred.resolve(userData);
      }, self.sleepInterval);

    }
    else {
      self.getBasicProfile(alias, true, deferred, showBanner);
    }
    return deferred.promise;
  }

  private getBasicProfileByAliasIncludingInActiveUser(alias: string): angular.IPromise<any> {
    var self = this;
    var url = self.searchProfileApiEndpoint + '/profile/' + alias+ '?IncludeInactive='+true;

    var deferred = self.q.defer();

    if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
      self.iUCount++;
      if (self.iUCount == 5) {
        self.fxplogger.logError(self.telemetrySource, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.SearchProfile_TokenMissing, null, null, null, null, ErrorSeverityLevel.Medium);
        return deferred.promise;
      }
      self.timeout(function () {
        self.getBasicProfileByAliasIncludingInActiveUser(alias);
      }, self.sleepInterval);
    }
    else {
      self.iUCount = 0;
      let bag = this.fxplogger.createPropertyBag();
      bag.addToBag('Url', url);
      const currentProcessName = TelemetryConstants.FXP_TELEMETRY_BASE_NAME + '.getBasicProfileByAliasIncludeInActiveUser';
      const eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'User Profile api call');

      self.fxplogger.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".UserProfileService.getBasicProfileByAliasIncludeInActiveUser", eventData, bag);

      return  self.$http.get(url);
    }
    return deferred.promise;
  }
  // get flighting context from user profile data
  getFlightingContext(data: any, userUpn: string): any {
    let roleGroupIdInternalId = CommonUtils.getPropertyValueCaseInsensitive(data, "RoleGroupId");
    if (roleGroupIdInternalId === null || roleGroupIdInternalId === undefined) {
      roleGroupIdInternalId = -1;
    }
    let resourceCategoryInternalId = CommonUtils.getPropertyValueCaseInsensitive(data, "ResourceCategoryId");
    if (resourceCategoryInternalId === null || resourceCategoryInternalId === undefined) {
      resourceCategoryInternalId = -1
    }
    let sDODomainInternalId = CommonUtils.getPropertyValueCaseInsensitive(data, "SDODomainId");
    if (sDODomainInternalId === null || sDODomainInternalId === undefined) {
      sDODomainInternalId = -1;
    }

    var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
    var flightingContext = {};
    var opFields = FxpAppSettings.LeftNavFlightingContextProperties;
    var opFieldsArray = opFields.split(',');
    var field = "";

    flightingContext["RoleGroupId"] = roleGroupIdInternalId;
    flightingContext["ResourceCategoryId"] = resourceCategoryInternalId;
    flightingContext["SDODomainId"] = sDODomainInternalId;
    flightingContext["userUpn"] = userUpn;

    opFieldsArray.forEach(fieldName => {
        field = CommonUtils.getPropertyValueCaseInsensitive(data, fieldName);
        flightingContext[fieldName] = field;
    });
    
    return flightingContext;
  }

  /**
  * Get flighting context for logged in user
  * @method Fxp.Services.UserProfileService.getLoggedInUserFlighitngContext
  * @example <caption> Example to invoke getLoggedInUserFlighitngContext</caption>
  *  userProfileService.getLoggedInUserFlighitngContext();
  */
  getLoggedInUserFlighitngContext(): angular.IPromise<any> {
    let self = this;
    let deferred = this.q.defer();
    let flightingContext = {};
    this.getBasicUserProfileLoggedInUser().then(
      (data) => {
        flightingContext = self.getFlightingContext(data, self.userInfoService.getLoggedInUserUpn());
        deferred.resolve(flightingContext);
      }, (error) => {
        this.fxplogger.logEvent('Fxp.userProfileService.getBasicUserProfileLoggedInUser', `BasicProfile:${this.userInfoService.getLoggedInUserOID()} failed.`);
        deferred.reject(error);
      });
    return deferred.promise;
  }

  /**
   * Get flighting context for Current user
   * @method Fxp.Services.UserProfileService.getCurrentUserFlighitngContext
   * @example <caption> Example to invoke getCurrentUserFlighitngContext</caption>
   *  userProfileService.getCurrentUserFlighitngContext();
   */
  getCurrentUserFlighitngContext(): angular.IPromise<any> {
    let self = this;
    let deferred = this.q.defer();
    let flightingContext = {};
    this.getBasicProfileByOID(this.userInfoService.getCurrentUserOID(), null).then(
      (data) => {
        flightingContext = self.getFlightingContext(data, self.userInfoService.getCurrentUserUpn());
        deferred.resolve(flightingContext);
      }, (error) => {
        this.fxplogger.logEvent('Fxp.userProfileService.getBasicProfileByAlias', `BasicProfile:${this.userInfoService.getCurrentUserOID()} failed.`);
        deferred.reject(error);
      });
    return deferred.promise;
  }


  /**
   * Gets user's basic profile details by connecting to the service through the url
   * @method Fxp.Services.UserProfileService.getBasicProfileSvc
   * @param {Alias} alias an alias is the name of a user upto the symbol @ in the upn.
   * @param {Boolean} deferred a boolean value which is false by default.
   * @example <caption> Example to invoke getBasicProfileSvc</caption>
   *  userProfileService.getBasicProfileSvc('logged in user alias', false);
   */
  private getProfileURL(userIdentifier: string, userIdentifierType: string) {
    var self = this;
    var url = '';
    if (self.profileApiEndpoint && userIdentifierType.toUpperCase() == "OBJECTID") {
      url = self.profileApiEndpoint + "/profile/basic/" + userIdentifier;

    }
    else if (self.profileApiEndpoint && userIdentifierType.toUpperCase() == "ALIAS") {
      url = self.profileApiEndpoint + "/profile/" + userIdentifier;
    }

    else {
      //call graph API
      url = self.graphAPIProfileUrl + ApplicationConstants.GraphApiProfileUrl;
    }
    return url;
  }
  private getBasicProfileSvc(userIdentifier: string, parentDeferred: any, showBanner?: boolean): IHttpPromise<string> {
    let self = this;
    var userIdentifierType = CommonUtils.isGuid(userIdentifier) ? "objectid" : "alias";
    let url = this.getProfileURL(userIdentifier, userIdentifierType);
    let deferred = parentDeferred || self.q.defer();

    if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
      self.iUCount++;
      if (self.iUCount == 5) {
        self.fxplogger.logError(self.telemetrySource, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.GetBasicProfile_TokenNotFound, null, null, null, null, ErrorSeverityLevel.Medium);
        self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
        return deferred.promise;
      }
      self.timeout(function () {
        self.getBasicProfileSvc(userIdentifier, deferred, showBanner);
      }, self.sleepInterval);
    }
    else {
      self.iUCount = 0;
      self.fxplogger.stopTrackPerformance(PerfMarkers.AdalTimeGetBasicProfile);
      let bag = this.fxplogger.createPropertyBag();
      bag.addToBag('Url', url);
      let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.Authorization.FetchBasicProfile';
      let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Basic Profile api call');

      bag.addToBag(TelemetryConstants.CLASSNAME, "UserProfileService");
      bag.addToBag(TelemetryConstants.METHODNAME, "getBasicProfileSvc");
      self.fxplogger.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".UserProfileService", eventData, bag);

      return this.$http.get(url);
    }
    return deferred.promise;
  }

  getBasicProfile(userIdentifier: string, isRefresh: boolean, pDeferred?: any, showBanner?: boolean): angular.IPromise<any> {
    let self = this;
    let userData = null;
    let receiveDate;
    let responseTime;
    let rootScope = this.rootScope;
    let deferred = pDeferred || this.q.defer();
    let telemetryContext = this.telemetryContext;
    showBanner = this.getShowBannerValue(showBanner);
    var userIdentifierType = CommonUtils.isGuid(userIdentifier) ? "objectid" : "alias";
    if (!isRefresh) {
      return self.getBasicUserProfile(deferred, showBanner);
    }
    else {
      let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.Authorization.GetBasicProfileFromCache';
      let customProperties = self.fxplogger.createPropertyBag();
      customProperties.addToBag(TelemetryConstants.CLASSNAME, "UserProfileService");
      customProperties.addToBag(TelemetryConstants.METHODNAME, "getBasicProfile");
      customProperties.addToBag(userIdentifierType, userIdentifier);
      var localStorageProfile = this.getProfileFromLocalStorage(userIdentifier);
      if (localStorageProfile) {
        self.dispatchProfileActions(localStorageProfile, userIdentifier);
        self.timeout(function () {
          try {
            deferred.notify(JSON.parse(localStorageProfile));
            let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Returned Basic Profile from browser cache');
            self.fxplogger.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".UserProfileService", eventData, customProperties);
          }
          catch (exception) {
            self.fxplogger.logException(currentProcessName, new Error("Invalid JSON in local Storage for Basic Profile"), customProperties, null, null, ErrorSeverityLevel.Medium, ErrorCodes.ProfileService_ErrorNotifyingCachedProfile);
          }
        }, self.sleepInterval);
      }

      let sendDate = new Date();
      this.fxplogger.startTrackPerformance(PerfMarkers.GetBasicProfileWithAdal);
      this.fxplogger.startTrackPerformance(PerfMarkers.AdalTimeGetBasicProfile);
      self.getBasicProfileSvc(userIdentifier, false, showBanner).then(function (data) {
        userData = data;
        // Logging a metric with the response

        receiveDate = new Date();
        responseTime = receiveDate.getTime() - sendDate.getTime(); // in millisecs
        var propbag = self.fxplogger.createPropertyBag();
        propbag.addToBag(FxpConstants.metricConstants.Status, userData.status);
        propbag.addToBag(FxpConstants.metricConstants.StatusText, userData.statusText);

        propbag.addToBag(FxpConstants.metricConstants.StartTime, sendDate.toUTCString());
        propbag.addToBag(FxpConstants.metricConstants.EndTime, receiveDate.toUTCString());
        propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.GetBasicProfileSvcName);
        let url = self.getProfileURL(userIdentifier, userIdentifierType);
        propbag.addToBag(FxpConstants.metricConstants.ServiceURL, url);
        propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);

        propbag.addToBag(FxpConstants.metricConstants.SessionId, rootScope.sessionId);
        propbag.addToBag(userIdentifierType, userIdentifier);
        propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, userData.BusinessRole);
        propbag.addToBag(FxpConstants.metricConstants.Geography, telemetryContext.getGeography());
        self.fxplogger.stopTrackPerformance(PerfMarkers.GetBasicProfileWithAdal);
        self.fxplogger.logMetric(self.telemetrySource, FxpConstants.metricConstants.UserProfileService, responseTime, propbag);

        if (userData.data != undefined && userData.data != null) {
          userData = userData.data;
          if (userData.hasOwnProperty("CacheHit")) {
            delete userData["CacheHit"];
          }
          var userInfo = new UserInfo();
          if (self.profileApiEndpoint) {
            for (var attr in userData) {
              userInfo[attr] = userData[attr];
            }

            // Adding BusinessDomain
            userInfo.BusinessDomain = userData.SDODomain;
            userInfo.AddressBookTitleDesc = userData.AddressBookTitleDesc;
            //Adding RoleGrouId
            userInfo.RoleGroupId = userData.RoleGroupID;
            rootScope.BusinessRole = userInfo.BusinessRole;

            // Adding Email
            userInfo.Email = userData.EmailName;

            // Adding FirstName to FullName
            if (!CommonUtils.isNullOrEmpty(userInfo.DisplayName)) {
              userInfo.FullName = userInfo.DisplayName;
            }

            // Adding MiddleName to FullName
            if (!CommonUtils.isNullOrEmpty(userInfo.MiddleName)) {
              userInfo.FullName += (userInfo.FullName.length > 0 ? " " : "") + userInfo.MiddleName;
            }

            // Adding LastName to FullName
            if (!CommonUtils.isNullOrEmpty(userInfo.LastName)) {
              userInfo.FullName += (userInfo.FullName.length > 0 ? " " : "") + userInfo.LastName;
            }

            //Adding BusinessRoleDisplayName
            if (!CommonUtils.isNullOrEmpty(userInfo.BusinessRole)) {
              if (!CommonUtils.isNullOrEmpty(userInfo.Seniority)) {
                userInfo.BusinessRoleDisplayName = userInfo.Seniority + " " + userInfo.BusinessRole;
              }
              else {
                userInfo.BusinessRoleDisplayName = userInfo.BusinessRole;
              }
            }

            //Checking isDefaultProfile flag
            userInfo.IsDefaultProfile = false;

            //Checking BusinessRole and BusinessRoleId
            if ((userInfo.BusinessRoleId == null || userInfo.BusinessRoleId == 0) ||
              (CommonUtils.isNullOrEmpty(userInfo.BusinessRole))) {
              self.fxplogger.logError(self.telemetrySource, rootScope.fxpUIConstants.UIMessages.UserProfileBusinessRoleMissingError.ErrorMessageTitle, ErrorCodes.GetBasicProfile_BusinessRoleMissing, null, null, null, null, ErrorSeverityLevel.High);
              if (showBanner) {
                self.fxpMessageSvc.addMessage(rootScope.fxpUIConstants.UIMessages.UserProfileBusinessRoleMissingError.ErrorMessage, FxpConstants.messageType.error);
              }
            }

            // Adding old tenant
            var tenantMapping = self.fxpConfiguration.FxpAppSettings["UserTenantMapping"];
            if (tenantMapping) {
              tenantMapping = JSON.parse(tenantMapping);
              var newTenant = userInfo.TenantName;
              if (tenantMapping[newTenant]) {
                userInfo.MappedTenantName = tenantMapping[newTenant];
              } else {
                userInfo.MappedTenantName = newTenant;
              }
            }

            // Checking RoleGroup
            if ((!userInfo.RoleGroupId || userInfo.RoleGroupId <= 0) ||
              (CommonUtils.isNullOrEmpty(userInfo.RoleGroupName))) {
              propbag = self.fxplogger.createPropertyBag();
              propbag.addToBag(FxpConstants.metricConstants.SessionId, rootScope.sessionId);
              propbag.addToBag(FxpConstants.metricConstants.TimeStamp, FxpLogHelper.getTimeStamp());
              //change
              // propbag.addToBag(FxpConstants.metricConstants.UserUPN, alias);
              propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);
              propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, userInfo.BusinessRole);
              propbag.addToBag(FxpConstants.metricConstants.Geography, telemetryContext.getGeography());
              self.fxplogger.logError(self.telemetrySource, rootScope.fxpUIConstants.UIMessages.UserProfileRoleGroupMissingError.ErrorMessageTitle, ErrorCodes.GetBasicProfile_RoleGroupMissing, "", propbag, null, null, ErrorSeverityLevel.Critical);
            }

          } else {
            //read data incase graph api call
            userInfo = self.getProfileDataFromGraphResponse(userData);
          }

          // adding user profile data to the local 
          self.setLocalStorageProfile(userInfo);
          let localStorageProfile = JSON.stringify(userInfo);
          self.dispatchProfileActions(localStorageProfile, userIdentifier);
          var responseEndTime = performance.now();
          var responseTotalDuration = responseEndTime - self.responseStartTime;
          self.isBasicProfileRefreshed = true;
          self.rootScope.$broadcast(FxpBroadcastedEvents.OnBasicUserProfileRefresh);
        }
        else {
          self.fxplogger.logError(self.telemetrySource, this.rootScope.fxpUIConstants.UIMessages.UserProfileNotFoundError.ErrorMessageTitle, ErrorCodes.GetBasicProfile_ProfileNotFound, null, null, null, null, ErrorSeverityLevel.Critical);
          if (self.isProfileRequired && showBanner)
            self.fxpMessageSvc.addMessage(rootScope.fxpUIConstants.UIMessages.UserProfileNotFoundError.ErrorMessage, FxpConstants.messageType.error);
        }

        deferred.resolve(userInfo);
      }).catch(function (err) {
        self.fxplogger.stopTrackPerformance(PerfMarkers.GetBasicProfileWithAdal);
        self.rootScope.isBlockedCSAUser = false;

        if (err && err.data && err.data.ErrorCode && err.data.ErrorCode.toString() === self.fxpConfiguration.FxpAppSettings.CSAErrorCode) {
          self.rootScope.isBlockedCSAUser = true;
        }
        // property bag for error
        if (self.isProfileRequired || self.rootScope.isLoginRequired || self.rootScope.isBlockedCSAUser) {
          var propbag = self.fxplogger.createPropertyBag();
          receiveDate = new Date();
          responseTime = receiveDate.getTime() - sendDate.getTime(); // in millisecs
          propbag.addToBag(FxpConstants.metricConstants.StartTime, sendDate.toUTCString());
          propbag.addToBag(FxpConstants.metricConstants.EndTime, receiveDate.toUTCString());
          propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.GetBasicProfileSvcName);
          propbag.addToBag(FxpConstants.metricConstants.IsLoginRequired, self.rootScope.isLoginRequired);
          if (err != null) {
            propbag.addToBag(FxpConstants.metricConstants.Status, err.status);
            propbag.addToBag(FxpConstants.metricConstants.StatusText, err.statusText);
            propbag.addToBag(FxpConstants.metricConstants.ErrorUrl, err.config ? err.config.url : '');
            propbag.addToBag(FxpConstants.metricConstants.IsCSABlockedUser, self.rootScope.isBlockedCSAUser.toString());

            if (err.status == "404") {
              self.fxplogger.logEvent(`${self.telemetrySource}.getBasicProfile`, CustomEvents.ProfileNotFound, propbag);
            }
            else {
              self.logBasicProfileFailureError(propbag);
            }

            //Added a flag to show/hide banner. By default banner will be visible. User need to send false value to hide the flag
            if (showBanner && !self.rootScope.isBlockedCSAUser) {
              self.fxpMessageSvc.addMessage(rootScope.fxpUIConstants.UIMessages.ProfileServiceCallFailedError.ErrorMessage, FxpConstants.messageType.error);
            }
          } else {
            propbag.addToBag(FxpConstants.metricConstants.ServiceURL, self.getProfileURL(userIdentifier, userIdentifierType));
            propbag.addToBag(FxpConstants.metricConstants.ErrorText, "Error object is null");
            self.logBasicProfileFailureError(propbag);
          }
          deferred.reject(err);
        }
        else {
          var userInfo = self.getDeafultUserInfo();
          self.isBasicProfileRefreshed = true;
          self.setLocalStorageProfile(userInfo);
          let localStorageProfile = JSON.stringify(userInfo);
          self.dispatchProfileActions(localStorageProfile, userIdentifier);
          deferred.resolve(userInfo);
        }
      });
    }

    return deferred.promise;
  }
  private setLocalStorageProfile(profile: UserInfo) {
    let alias = profile.Alias;
    let OID = profile.AadObjectId;
    let localStorageProfile = JSON.stringify(profile);
    if (!CommonUtils.isNullOrEmpty(alias))
      localStorage[CommonUtils.getSessionStorageUserInfoKey(alias)] = localStorageProfile;
    if (!CommonUtils.isNullOrEmpty(OID))
      localStorage[CommonUtils.getSessionStorageUserInfoKey(OID)] = localStorageProfile;
  }

  // need to save for both so will work always both here
  private getProfileFromLocalStorage(userIdentifier) {
    if (!userIdentifier)
      return null;

    let profileStorageKey = CommonUtils.getSessionStorageUserInfoKey(userIdentifier);
    if (!profileStorageKey)
      return null;
      
    var localStorageProfile = localStorage[profileStorageKey];
    return localStorageProfile;
  }

  private dispatchProfileActions(localStorageProfile: any, userIdentifier: string) {
    let self = this;
    if (userIdentifier == self.userInfoService.getLoggedInUserOID() || userIdentifier == self.loggedInUserAlias) {
      //dispatch action with payload as localStorage
      self.globalStoreService.DispatchGlobalAction("Platform", AddLoggedInUserProfile(JSON.parse(localStorageProfile)));
      this.setLoggedInUser(localStorageProfile);
    }
    if (userIdentifier == self.userInfoService.getCurrentUserOID() || userIdentifier == self.currentUserAlias)
    {
      self.globalStoreService.DispatchGlobalAction("Platform", AddCurrentUserProfile(JSON.parse(localStorageProfile)));
      
    }
  }

  /**
   * Logs exception occured in get basic profile
   * @method Fxp.Services.UserProfileService.logBasicProfileFailureError
   * @param {object} propbag contains all property bag values.
   *  userProfileService.logBasicProfileFailureError(propbag);
   */
  logBasicProfileFailureError(propbag: LogPropertyBag): void {
    var self = this;
    self.fxplogger.logError(self.telemetrySource, self.rootScope.fxpUIConstants.UIMessages.ProfileServiceCallFailedError.ErrorMessageTitle, ErrorCodes.BasicProfile_UnknownError, null, propbag, null, null, ErrorSeverityLevel.Critical);
  }

  /**
   * Gets logged in user's basic profile details by getBasicUserProfileLoggedInUser to the service through the url
   * @method Fxp.Services.UserProfileService.getBasicUserProfile
   * @example <caption> Example to invoke getBasicUserProfileLoggedInUser</caption>
   *  userProfileService.getBasicUserProfileLoggedInUser();
   */
  getBasicUserProfileLoggedInUser(): angular.IPromise<any> {
    return this.getBasicProfileByOID(this.userInfoService.getLoggedInUserOID(), null);
  }

  setLoggedInUser(localStorageProfile) {
    let userProfile = JSON.parse(localStorageProfile);
    this.loggedInUserAlias = userProfile.alias;
    this.userInfoService.setLoggedInUser(this.loggedInUserAlias);
    if (!this.isObo())
    {
      this.userInfoService.setCurrentUser(this.loggedInUserAlias);
      this.setCurrentUser(this.loggedInUserAlias);
    }
  }

  setCurrentUser(alias: string) {
    if (alias) {
      this.userAlias = alias;
      this.currentUserAlias = alias;
      this.userInfoService.setCurrentUser(alias);
    }
  }

  isObo(): boolean {
    return this.userInfoService.isActingOnBehalfOf();
  }
  /**
   * Returns search results from the profile API for the specified search string
   * @method Fxp.Services.UserProfileService.searchProfile
   * @param {string} searchString search string for a user alias.
   * @example <caption> Example to invoke searchProfile</caption>
   *  userProfileService.searchProfile('search string');
   */
  searchProfile(searchString, parentDiffered?) {
    var self = this;
    var url = self.searchProfileApiEndpoint + '/profile/searchprofile?searchText=' + searchString;

    var deferred = parentDiffered || self.q.defer();

    if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
      self.iUCount++;
      if (self.iUCount == 5) {
        self.fxplogger.logError(self.telemetrySource, self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.SearchProfile_TokenMissing, null, null, null, null, ErrorSeverityLevel.Medium);
        self.fxpMessageSvc.addMessage(self.rootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
        return deferred.promise;
      }
      self.timeout(function () {
        self.searchProfile(searchString, deferred);
      }, self.sleepInterval);
    }
    else {
      self.iUCount = 0;
      let bag = this.fxplogger.createPropertyBag();
      bag.addToBag('Url', url);
      //this.fxplogger.logEvent('Fxp.userProfileService.getBasicProfileSvc', 'GetBasicProfile:StartingServiceCall', bag);
      const currentProcessName = TelemetryConstants.FXP_TELEMETRY_BASE_NAME + '.UserProfileSearch';
      const eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'User Profile api call');

      self.fxplogger.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".UserProfileService.searchProfile", eventData, bag);

      return (window["tenantConfiguration"].ProfileStore && window["tenantConfiguration"].ProfileStore.SearchAPIHeaders) ?
        self.$http.get(url, { headers: window["tenantConfiguration"].ProfileStore.SearchAPIHeaders }) : self.$http.get(url);
    }
    return deferred.promise;
  }



  private getProfileDataFromGraphResponse(userData: any): any {
    var userInfo = new UserInfo();
    userInfo.FirstName = userData.givenName;
    userInfo.LastName = userData.surname;
    userInfo.FullName = userData.displayName;
    userInfo.Email = userData.userPrincipalName;
    userInfo.DisplayName = userData.displayName;
    userInfo.Alias = userData.mailNickname;
    userInfo.PersonnelNumber = userData.immutableId;
    userInfo.BusinessRole = userData.jobTitle || {};
    userInfo.BusinessRoleDisplayName = userData.jobTitle || {};
    userInfo.IsDefaultProfile = false;

    for (let item in userData) {
      if (item.indexOf("ReportsToFullName") > 0) {
        userInfo.ReportsToDisplayName = userData[item]
        userInfo.ReportsToFullName = userInfo.ReportsToDisplayName;
        break;
      }
    }
    return userInfo;
  }
  private getDeafultUserInfo() {
    var aadBasicUserInfo = this.getAADBasicInfoFromLocalStorage();
    var userInfo = new UserInfo();
    if (aadBasicUserInfo === null) {
      userInfo.Email = "";
      userInfo.FirstName = "";
      userInfo.LastName = "";
      userInfo.FullName = "";
      userInfo.DisplayName = "";
    }
    else {
      console.log('Fetching user info from AAD user info.')
      var userProfileAAD = aadBasicUserInfo['profile'];
      userInfo.Email = userProfileAAD['upn'];
      userInfo.FirstName = userProfileAAD['given_name'];
      userInfo.LastName = userProfileAAD['family_name'];
      userInfo.FullName = userProfileAAD['name'];
      userInfo.DisplayName = userProfileAAD['name'];
    }

    userInfo.IsDefaultProfile = true;
    userInfo.BusinessRole = "0";
    userInfo.BusinessRoleDisplayName = "0";

    this.rootScope.$broadcast(CustomEvents.OnProfileFromGraphAPI);
    this.rootScope.isBotEnabled = false;

    return userInfo;
  }

  private getAADBasicInfoFromLocalStorage(): any {
    return angular.fromJson(localStorage.getItem("AADBasicInfo"));
  }

  private getShowBannerValue(showBanner: boolean) {
    //Setting the showBanner variable to true when the value is undefined. Banner will be showing by default 
    return (showBanner === false) ? false : true;
  }
}
