<div class="fxp_breadcrumb-container" *ngIf="breadCrumbEnabled" id="fxp_Breadcrumb">
  <nav role="navigation" aria-label="Secondary">
    <ol>
      <li *ngFor="let item of fxpBreadcrumb; let last = last" 
          [attr.title]="last ? '' : item.displayName">
        <a *ngIf="!last"
           class="active"
           [attr.href]="item.href" 
           [attr.aria-label]="first ? 'You are here ' + item.displayName : item.displayName">
          {{ item.displayName }}
        </a>
        <i *ngIf="!last" class="icon-layout icon-chevron-right"></i>
        <span *ngIf="last">
          <span class="sr-only">Current </span>
          {{ item.displayName }}
        </span>
      </li>
    </ol>
  </nav>
</div>
