<div class="btn-group pull-left notificationflyout dropdown" [class.open]="isNotificationFlyoutOpen">
    <button id="notification-open" type="button" class="icon-container withBorder"  [attr.aria-expanded]="isNotificationFlyoutOpen"
        [attr.aria-label]="hasNotificationError ? notificationErrorMsg : (unreadNotificationCount > 0 ? unreadNotificationCount + ' unread notification in notification dialog ' : 'notification dialog')"
        (keydown)="renderHeaderForKeydown($event)"
        (click)="toggleNotificationFlyout(); getStoredNotification(); renderNotificationButtonForClick($event)"
        (blur)="renderHeaderForFocusout($event)">
        <i class="icon icon-notifications" aria-hidden="true"></i>
        <sup *ngIf="unreadNotificationCount > 0 || hasNotificationError" class="notification-count">
            {{ hasNotificationError ? '!' : (unreadNotificationCount > 99 ? '99+' : unreadNotificationCount) }}
        </sup>
    </button>
    <div class="notification-overlay" *ngIf="isNotificationFlyoutOpen" (click)="closeNotificationFlyout()"></div>
    <div class="notification-dialog dropdown-menu" role="dialog" aria-labelledby="notification-open"
        *ngIf="isNotificationFlyoutOpen">
        <ul class="notification-list">
            <li class="notification-content">
                <h2 class="notification-heading">
                    Notifications
                    <div class="pull-right">
                        <a class="notificationClose" role="button" id="closeNotification"
                            [title]="!hasNotificationError && !notificationCollection.length ? 'Nothing new right now.' : ''"
                            (click)="closeNotificationFlyout()">
                            <i class="icon icon-close"></i>
                        </a>
                    </div>
                </h2>
                <div>
                    <div class="dnd-label">Do not disturb</div>
                    <div>
                        <label class="pull-left input-switch input-switch--focus">
                            <input id="dndnotificaion" type="checkbox" class="pull-left notification-dnd"
                                [(ngModel)]="isNotificationDNDEnabled"
                                (change)="onDNDValueChange(isNotificationDNDEnabled)"
                                [attr.aria-label]="'Do not disturb mode: ' + (isNotificationDNDEnabled ? 'On' : 'Off')"
                                [disabled]="actOnBehalfOfUserActive" tabindex="0">
                            <div role="presentation" class="slider round"></div>
                            <div role="presentation" class="switch-lable">{{ isNotificationDNDEnabled ? 'On' : 'Off' }}
                            </div>
                        </label>
                        <div class="action-buttons pull-right">
                            <button class="fxp-btn fxp-btn--action" (click)="markAllNotificationsAsRead()"
                                [disabled]="actOnBehalfOfUserActive || hasNotificationError || !unreadNotificationCount"
                                aria-label="Mark all notifications as read" title="Mark all as read"
                                id="btn_markallread">
                                <span class="font-caption-alt">Mark all as read</span>
                            </button>
                            <span class="action-separator">|</span>
                            <button class="fxp-btn fxp-btn--action" (click)="dismissAllNotifications()"
                                [disabled]="actOnBehalfOfUserActive || hasNotificationError || !notificationCollection.length"
                                aria-label="Dismiss All notifications" title="Dismiss All" id="btn_dismissall">
                                <span class="font-caption-alt">Dismiss All</span>
                            </button>
                        </div>
                        <div class="clearfix"></div>
                        <span id="notificationAssertMessage" aria-live="assertive" role="alert"
                            style="position: absolute!important; clip: rect(1px, 1px, 1px, 1px);"></span>
                    </div>
                </div>
            </li>
            <ng-container *ngIf="!hasNotificationError">
                <li class="notification" *ngFor="let item of notificationCollection; let i = index">
                    <div class="notification-left">
                        <i [ngClass]="item.icon"></i>
                    </div>
                    <div class="notification-body">
                        <div class="wrap notifi-subject">
                            <div class="notification-body-main fxpTabbableElem without-view"
                                [attr.multiline-ellipsis]="4" (click)="readNotification(item)"
                                [tabindex]="item.status.name === 'Sent' ? 0 : -1"
                                [ngClass]="{'read': item.status.name === 'Read', 'un-read': item.status.name === 'Sent', 'has-action': item.hasAction}"
                                [attr.aria-label]="item.subject + ' Notification Message ' + (i + 1) + ' of ' + notificationCollection.length + '. ' + (item.status.name === 'Sent' ? 'Tap to read it' : '')">
                                <p class="notification-subject"
                                    [ngClass]="{'read': item.status.name === 'Read', 'un-read': item.status.name === 'Sent', 'has-action': item.hasAction}"
                                    [innerHTML]="item.subject"></p>
                                <div [innerHTML]="item.content"
                                    [ngClass]="{'read': item.status.name === 'Read', 'un-read': item.status.name === 'Sent', 'has-action': item.hasAction}"
                                    class="notification-content"></div>
                            </div>
                            <p>{{ item.publishedOn | date:'MM/dd/yyyy HH:mm a' }}</p>
                        </div>
                    </div>
                    <div class="notification-right">
                        <button [disabled]="actOnBehalfOfUserActive" type="button" class="icon-close deleteNotification"
                            [attr.aria-label]="'Delete ' + (item.status.name === 'Sent' ? 'Unread' : 'Read') + ' ' + item.subject + ' Notification ' + (i + 1) + ' of ' + notificationCollection.length"
                            id="delete-notification-{{i}}" (click)="deleteNotification(item, $event)">
                            <i class="icon icon-close"></i>
                        </button>
                    </div>
                </li>
            </ng-container>
            <li *ngIf="hasNotificationError">
                <div class="notification-error">
                    <div class="errorIcon"><i class="icon icon-error"></i></div>
                    <div class="error-message">{{ notificationErrorMsg }}</div>
                </div>
            </li>
            <li>
                <div *ngIf="!hasNotificationError && !notificationCollection.length" class="message font-caption-alt">
                    Nothing new right now.
                </div>
                <div *ngIf="!hasNotificationError && moreNotificationsAvailable" class="load-more font-body"
                    title="Load more notifications">
                    <a href="#" (click)="loadMore($event)" aria-label="Load more notifications" id="loadMore">
                        Load More
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>